@import "stolen_bootstrap.css";

body, html { width: 100%; height: 100%; padding: 0; margin: 0;
  font-family: 'Work Sans', Arial, Helvetica, 'Nimbus Sans L', sans-serif;
}
div.row-wrapper { min-height: calc(100% - 81px - 344px); }
div#footer { width: 100%; height: 150px; position: relative; background: linear-gradient(90deg, rgba(15,58,128,1) 0%, rgba(0,171,197,1) 100%); color: #ffffff; padding-top: 25px; text-align: center; }
div#footer > footer > span { text-transform: uppercase; }
.row-container { display: flex; width: 100%; min-height: 100%; height: 100%; flex-direction: column; border: none;
  overflow-x: hidden; margin-bottom: -200px; }
.first-row { background: linear-gradient(90deg, rgba(15,59,128,1) 0%,rgba(0,171,197,1) 100%); border: none;
  width: 100%; padding-left: 20px; padding-top: 2px; display: flex; align-items: center; }
.first-row img { padding-right: 20px; border-right-width: 1px; border-right-style: solid;
  border-right-color: rgba(255,255,255,0.15); }
.menu { font-family: 'Work Sans', Arial, Helvetica, 'Nimbus Sans L', sans-serif; font-size: 16px; line-height: 20px; font-weight: 700;
  display: flex; flex-grow: 1; }
.menu > div { padding-right: 20px; border-right-width: 1px; border-right-style: solid;
  border-right-color: rgba(255,255,255,0.15); }
.menu iframe { padding-left: 20px; height: calc(100vh - 81px); }
.second-row { flex-grow: 1; margin: 0; padding: 0; border: 0; }
.signin { position: absolute; height: 80px; right: 40px; padding-right: 0px; padding-top: 15px;
  padding-left: 20px; border-left-width: 1px; border-left-style: solid; border-left-color: rgba(255,255,255,0.15);
}
.signin > div > a { color: #ffffff; text-decoration: none; border-color: #ffffff; height: 42px; font-size: 16px;
  font-weight: 600; font-style: normal; line-height: 42px; display: inline-block; padding: 0 30px; cursor: pointer;
  text-align: center; border-width: 2px; border-style: solid; border-radius: 25px; transition: all .3s ease-in-out;
  vertical-align: baseline;
}
.signin > div > a:hover { background-color: #ffffff; color: #0f3b80; }
a { text-decoration: underline solid #000000; color: #000000; }
a:visited { text-decoration: underline solid #000000; color: #000000; }

img.icon { width: 32px; height: 32px; }
.left {
  float: left;
  display: flex;
  flex-direction: column;
  width: 20vw;
}
.left-item {
  display: flex;
  align-items: center;
  background: #0f3b80;
  border-radius: 5px;
  margin: 10px 10px 0 10px;
  padding-right: 10px;
  cursor: pointer;
}
.left-item a { color: #ffffff; text-decoration: none; }

.right { float: left; overflow-y: hidden; }
.right-item {
  width: 35vw;
  margin-top: 10px;
  margin-left: 10px;
  border-radius: 5px;
  background: #eefcff;
}
.right-item-header {
  display: flex;
  align-items: center;
  padding-right: 10px;
  background: #0f3b80;
  border-radius: 5px;
  cursor: pointer;
}
.right-item-header > a { color: #ffffff; text-decoration: none; }
.right-item-list > ul { list-style-type: none; margin: 0; }
.right-item-list li::before { content: "> "; color: #00abc5; }
.right-item-list a { color:#0f3b80; text-decoration: none; font-weight: 500; }
.selected { background-color: #00abc5; }

#signed_in_username { color: #ffffff; position: absolute; right: 250px; top: 20px; }
#sign_in_section { top: 0; }
.form-group { padding: 5px; }
a.header_button { text-decoration: none!important; }
input:not([type=button]):not([type=checkbox]):not([type=file]):not([type=hidden]):not([type=image]):not([type=radio]):not([type=reset]):not([type=submit]):not([type=color]):not([type=range]), textarea {
  background-color: #f2f6f9; color: #000000;
  font-family: 'Work Sans', Arial, Helvetica, 'Numbus Sans L', sans-serif;
  font-size: 16px; font-weight: 500; font-style: normal;
  height: 46px; line-height: normal;
  padding: 0; max-width: 100%;
  border-radius: 0; border: 1px solid #c0c0c0;
  margin: 0; border-radius: 0; border: 1px solid #c0c0c0; box-sizing: border-box;
  padding: 0 10px 0 10px;
}
button,input[type=button],input[type=submit] {
  transition: .3s ease-in-out; cursor: pointer; padding: 0 30px;
  background-color: #0f3b80; color: #ffffff; border-color: #0f3b80;
  font-family: 'Work Sans', Arial, Helvetica, 'Nimbus Sans L', sans-serif;
  font-size: 16px; line-height: 0; font-weight: 700; font-style: normal;
  border-width: 2px; border-style: solid; border-radius: 25px;
  height: 42px;
}
button:hover,input[type=button]:hover,input[type=submit]:hover {
  background-color: #00abc5;
  color: #b8d400;
  border-color: #00abc5;
}
button:disabled {
  opacity: .35 !important;
  background-color: #0f3b80; color: #ffffff; border-color: #0f3b80;
}
.btn-type {
  padding: 0 30px;
  height: 42px;
  font-size: 16px;
  font-weight: 600;
  line-height: 42px;
  border-radius: 25px;
  transition: .3s ease-in-out;
  border: 2px solid #fff;
}
textarea { height: 100px; }

.fade-in {
  opacity: 1; animation-name: fadeInOpacity; animation-iteration-count: 1;
  animation-timing-function: ease-in; animation-duration: .5s;
}
.fade-out {
  opacity: 0; animation-name: fadeOutOpacity; animation-iteration-count: 1;
  animation-timing-function: ease-out; animation-duration: .5s;
}
@keyframes fadeOutOpacity { 0% { opacity: 1; } 100% { opacity: 0; } }
@keyframes fadeInOpacity  { 0% { opacity: 0; } 100% { opacity: 1; } }
.alert {
  border-width: 5px; border-style: solid; margin-top: 10px; transition: .3s ease-in-out;
  position: fixed; top: 0; left: 33%; max-width: 33%; padding: 15px; z-index: 9999;
}
.alert-success { border-color: #00ff00; background: #e8ffe8; }
.alert-danger  { border-color: #ff0000; background: #ffe8e8; }
.help-block { color: #ff0000; }
.help-approval { color: #00c000; }
.warning { border-width: 5px; border-style: solid; margin-top: 10px; padding: 15px; 
           border-color: #ff0000; color: #000000; }

table.form-table tr td { vertical-align: top; }
table.form-table tr td.label { padding-top: 12px; }

.spinner {
  margin: 0 auto;
  position: relative;
  width: 75px;
  height: 75px;
  background: no-repeat center/97% url(logo_white.png);
  -webkit-animation: rotation .8s linear 0s infinite normal none;
  -moz-animation: rotation .8s linear 0s infinite normal none;
  -ms-animation: rotation .8s linear 0s infinite normal none;
  -o-animation: rotation .8s linear 0s infinite normal none;
  animation: rotation .8s linear 0s infinite normal none;
  border-left: 10px solid rgb(15,59,128);
  border-right: 10px solid rgb(15,59,128);
  border-bottom: 10px solid rgb(15,59,128);
  border-top: 10px solid rgb(15,59,128);
  border-radius: 100%;
  background-color: rgb(15,59,128);
}

table.detail-table td {
  padding-right: 10px;
}

table.host-table td {
  padding-left: 5px;
  padding-right: 5px;
}
table.host-table th {
  text-align: left;
  padding-right: 10px;
  padding-left: 0;
}

@keyframes rotation {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@-webkit-keyframes rotation {
  from {-webkit-transform: rotate(0deg); }
  to {-webkit-transform: rotate(360deg); }
}

@-moz-keyframes rotation {
  from {-moz-transform: rotate(0deg); }
  to {-moz-transform: rotate(360deg); }
}

@-ms-keyframes rotation {
  from {-ms-transform: rotate(0deg); }
  to {-ms-transform: rotate(360deg); }
}

.dropzone {
  width: 450px;
  height: 200px;
  padding: 2rem;
  text-align: center;
  border: dashed 1px #979797;
  position: relative;
  margin: 0 auto;
}

:is(.dropzone) input {
  opacity: 0;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

:is(.dropzone) label {
  color: white;
  width: 183px;
  height: 44px;
  border-radius: 21.5px;
  background-color: #0f3b80;
  padding: 8px 16px;
  cursor: pointer;
  transition: .3s ease-in-out;
  font-weight: bold;
}
:is(.dropzone) label:hover {
  background-color: #00abc5;
  color: #b8d400;
  border-color: #00abc5;
  font-weight: bold;
}

:is(.dropzone) h4 {
  font-size: 20px;
  font-weight: 600;
  color: #38424c;
}

.fileOver {
  animation: shake 1s;
  animation-iteration-count: infinite;
}

.files-list {
  margin-top: 1.5rem;
  width: 50%;
  margin-left: 25%;
  margin-bottom: 1.5rem;
}
.files-list.single-file {
  display: flex;
  flex-grow: 1;
  padding: 0.5rem;
  justify-content: space-between;
  align-items: center;
  border: dashed 1px #979797;
  margin-bottom: 1rem;
}

.files-list.single-file.name {
  font-size: 14px;
  font-weight: 500;
  color: #353f4a;
  margin: 0;
}

.files-list.single-file.size {
  font-size: 12px;
  font-weight: 500;
  color: #a4a4a4;
  margin: 0;
  margin-bottom: 0.25rem;
}

.files-list.single-file.info {
  width: 100%;
}

@keyframes shake {
  0%   { transform: translate(1px, 1px) rotate(0deg); }
  10%  { transform: translate(-1px, -2px) rotate(-1deg); }
  20%  { transform: translate(-3px, 0px) rotate(1deg); }
  30%  { transform: translate(3px, 2px) rotate(0deg); }
  40%  { transform: translate(1px, -1px) rotate(1deg); }
  50%  { transform: translate(-1px, 2px) rotate(-1deg); }
  60%  { transform: translate(-3px, 1px) rotate(0deg); }
  70%  { transform: translate(3px, 1px) rotate(-1deg); }
  80%  { transform: translate(-1px, -1px) rotate(1deg); }
  90%  { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}
